import { FC, PropsWithChildren, createContext, useState } from 'react';

export const ProductionFilterContext = createContext<
	[string[], (locationIds: string[]) => void, boolean]
>([[], () => {}, false]);

export const ProductionFilterContextProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
	const [initialized, setInitialized] = useState(false);

	const handleSetSelectedLocationIds = (locationIds: string[]) => {
		if (!initialized) {
			setInitialized(true);
		}

		setSelectedLocationIds(locationIds);
	};

	return (
		<ProductionFilterContext.Provider
			value={[selectedLocationIds, handleSetSelectedLocationIds, initialized]}
		>
			{children}
		</ProductionFilterContext.Provider>
	);
};
