import { FC, ReactNode } from 'react';
import { Pages } from '../../Enums';
import './Actions.scss';
import { DatePicker } from '../Inputs/DatePicker/DatePicker';
import { AuxiliaryActions } from '../AuxiliaryAction/AuxiliaryActions';
import { getGranularity } from './utils/getGranularity';

/**
 * A component containing mostly groups of click actions
 */
type Props = {
	page: Pages;
	children: ReactNode;
};

export const ActionsContainer: FC<Props> = ({ page, children }) => {
	return (
		<div className="actions-container">
			<div className="actions-container__auxiliary-actions">
				<AuxiliaryActions>{children}</AuxiliaryActions>
			</div>
			<div className="actions-container__date-picker">
				{page !== Pages.PRODUCTION_PAGE && (
					<DatePicker
						granularity={getGranularity(page)}
						page={page}
						isEdited={true}
					/>
				)}
			</div>
		</div>
	);
};
