import { format } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { DateContext } from '../../Core/Contexts/DateContext';
import { LocationContext } from '../../Core/Contexts/LocationContext';
import {
	useGetProductEntriesFromDate,
	useUpdateEntries,
} from '../../Core/Hooks/EntryHooks';
import { useGetLocation } from '../../Core/Hooks/LocationHooks';
import { Props } from './WasteTable';

type AdditionalProps = {
	isFetching: boolean;
	isSaving: boolean;
	refresh: () => Promise<void>;
};

export const useWasteTableData = (): Props & AdditionalProps => {
	const [selectedDate] = useContext(DateContext);
	const [selectedLocation] = useContext(LocationContext);

	const {
		data: currentLocation,
		isLoading: isLocationLoading,
		isFetching: isLocationFetching,
		refetch: refetchLocation,
	} = useGetLocation(selectedLocation);

	const {
		data: entries,
		isLoading: areProductEntriesLoading,
		isFetching: areProductEntriesFetching,
		refetch: refetchProductEntries,
	} = useGetProductEntriesFromDate(
		selectedLocation,
		format(selectedDate[0], 'yyyy-MM-dd')
	);

	const { mutate: saveEntry, isLoading: isSaving } = useUpdateEntries();

	const isLoading = useMemo(
		() => isLocationLoading || areProductEntriesLoading,
		[areProductEntriesLoading, isLocationLoading]
	);
	const isFetching = useMemo(
		() => isLocationFetching || areProductEntriesFetching,
		[areProductEntriesFetching, isLocationFetching]
	);

	const refresh = useCallback(() => {
		return (
			Promise.all([refetchLocation(), refetchProductEntries()])
				// We want to return void
				.then(() => {})
		);
	}, [refetchProductEntries, refetchLocation]);

	return {
		currentLocation,
		entries,
		isLoading,
		isFetching,
		isSaving,
		refresh,
		saveEntry,
	};
};
