import { Pages } from '../Core/Enums';
import { FooterContainer } from '../Core/Components/Footer/Footer';
import './OrderPage.scss';
import { OrdersTable } from './OrderTable/OrderTable';
import { DatePicker } from '../Core/Components/MiniDatePicker/MiniDatePicker';
import { Header } from '../Core/Components/Header/Header';
import { Printable } from '../Core/Components/Printable/Printable';
import {
	LoaderButton,
	Props as LoaderButtonProps,
} from '../Core/Components/Inputs/LoaderButton/LoaderButton';
import { useOrdersTableData } from './OrderTable/OrderTableDataHook';
import { useCallback, useMemo } from 'react';

export const OrderPage = () => {
	// Get the data for the table
	const tableData = useOrdersTableData();

	// Get the state for the refresh button
	const refreshButtonState: LoaderButtonProps['state'] = useMemo(() => {
		return tableData.isFetching || tableData.isSaving ? 'loading' : 'idle';
	}, [tableData.isFetching, tableData.isSaving]);

	// Handler for the refresh button
	const handleRefresh = useCallback(() => {
		tableData.refresh();
	}, [tableData]);

	return (
		<div className="order-page">
			<div className="order-page__header">
				<Header
					page={Pages.ORDERS_PAGE}
					button={
						<LoaderButton state={refreshButtonState} onClick={handleRefresh} />
					}
				>
					<Printable
						printableComponent={<OrdersTable {...tableData} />}
						onBeforePrint={tableData.refresh}
						isLoading={tableData.isFetching}
					/>
					<DatePicker />
				</Header>
			</div>

			<div className="order-page__content">
				<OrdersTable {...tableData} />
			</div>
			<div className="order-page__footer">
				<FooterContainer page={Pages.ORDERS_PAGE} />
			</div>
		</div>
	);
};
