import { format } from 'date-fns';
import { FC, ReactNode, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { DateContext } from '../../Contexts/DateContext';
import { Pages } from '../../Enums';
import { generateTitle } from '../../Utils/GenerateTitles';
import { Icon } from '../Icon/Icon';
import './Printable.scss';
import classNames from 'classnames';

type Props = {
	printableComponent: ReactNode;
	page?: Pages;
	onBeforePrint?: () => Promise<void>;
	isLoading?: boolean;
};

export const Printable: FC<Props> = ({
	printableComponent,
	page = Pages.ORDERS_PAGE,
	onBeforePrint = () => Promise.resolve(),
	isLoading = false,
}) => {
	const [selectedDate] = useContext(DateContext);
	const headerTitle = generateTitle(selectedDate[0], page);
	const formattedStartDate = format(selectedDate[0], 'dd LLL');
	const formattedEndDate = selectedDate[1]
		? ` - ${format(selectedDate[1], 'dd LLL')}, ${format(
				selectedDate[1],
				'yyyy'
		  )}`
		: '';

	const printableRef = useRef<HTMLDivElement | null>(null);
	const handlePrint = useReactToPrint({
		// onBeforeGetContent: onBeforePrint,
		content: () => printableRef.current,
		pageStyle() {
			return page === Pages.ORDERS_PAGE || page === Pages.PRODUCTION_PAGE
				? `
		      @page {
		        size: A4 landscape;
		      }
		  `
				: `
		      @page {
		        size: A4 portrait;
		      }
		    `;
		},
	});

	return (
		<div className="Printable">
			<div className="Printable__Content" ref={printableRef}>
				<div className="Printable__Header">
					<h1>{headerTitle}</h1>
					<h2>{`${formattedStartDate}${formattedEndDate}`}</h2>
				</div>
				{printableComponent}
			</div>
			<Icon
				onClick={isLoading ? noop : handlePrint}
				name="Print"
				width={22}
				key="PrintIcon"
				className={classNames([], {
					'Printable__PrintIcon--loading': isLoading,
				})}
			/>
		</div>
	);
};

const noop = () => {};
