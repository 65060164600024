import {
	add,
	sub,
	getWeek,
	eachWeekOfInterval,
	eachDayOfInterval,
	startOfWeek,
} from 'date-fns';

/**
 *
 * Calculates the current week of the year
 * @returns the current week
 */

import { Granularity } from '../../../../Enums';

export type DateAsValue = {
	dateAsDate: Date;
	dateAsNumber: number;
};

export const generateDates = (
	granularity: Granularity,
	startDate: Date
): DateAsValue[] => {
	if (granularity === Granularity.WEEK) {
		const interval = eachWeekOfInterval(
			{
				start: startOfWeek(startDate, { weekStartsOn: 1 }),
				end: add(startDate, { weeks: 4 }),
			},
			{ weekStartsOn: 1 }
		);

		return interval.map((day) => {
			return {
				dateAsDate: day,
				dateAsNumber: getWeek(day, {
					weekStartsOn: 1,
					firstWeekContainsDate: 3,
				}),
			};
		});
	}
	if (granularity === Granularity.DAY) {
		const interval = eachDayOfInterval({
			start: startDate,
			end: add(startDate, { days: 4 }),
		});

		return interval.map((day) => {
			return {
				dateAsDate: day,
				dateAsNumber: day.getDate(),
			};
		});
	}
	return [];
};

export const spanForward = (
	granularity: Granularity,
	dateSpan: DateAsValue[]
): DateAsValue[] => {
	return generateDates(granularity, dateSpan[1].dateAsDate);
};

export const spanBackward = (
	granularity: Granularity,
	dateSpan: DateAsValue[]
): DateAsValue[] => {
	if (granularity === Granularity.WEEK) {
		return generateDates(
			granularity,
			sub(dateSpan[0].dateAsDate, { weeks: 1 })
		);
	}
	return generateDates(granularity, sub(dateSpan[0].dateAsDate, { days: 1 }));
};
