import classNames from 'classnames';
import { FC, useContext, useState } from 'react';
import { DateContext } from '../../../Contexts/DateContext';
import { Granularity, Pages } from '../../../Enums';
import { Icon } from '../../Icon/Icon';
import {
	DateAsValue,
	generateDates,
	spanBackward,
	spanForward,
} from './utils/DatePickerUtils';
import './DatePicker.scss';
import { isPassed } from './utils/isPassed';
import { isActive } from './utils/isActive';
// import { StatusDot } from './StatusDot';

type Props = {
	granularity: Granularity;
	isEdited: boolean;
	page?: Pages;
};

/**
 * A component of 5 buttons used to select the current date
 */
export const DatePicker: FC<Props> = ({ granularity, page }) => {
	const [selectedDate, setSelectedDate] = useContext(DateContext);
	const [dateSpan, setDateSpan] = useState(
		generateDates(granularity, selectedDate[0])
	);

	return (
		<div className="date-picker">
			<div
				className="date-picker__arrow-left"
				onClick={() => setDateSpan(spanBackward(granularity, dateSpan))}
			>
				<Icon name="Path" width={10} />
			</div>
			<div className="date-picker__dates">
				{dateSpan.map((date: DateAsValue) => {
					return (
						<div
							className="date-picker__action-container"
							key={date.dateAsNumber}
						>
							<button
								className={classNames('date-picker__dates__date', {
									'date-picker__dates__date--active': isActive(
										date,
										granularity,
										selectedDate[0]
									),
									'date-picker__dates__date--passed':
										isPassed(date.dateAsDate, granularity) &&
										!isActive(date, granularity, selectedDate[0]),
								})}
								onClick={() => {
									setSelectedDate([date.dateAsDate, null]);
								}}
							>
								<label
									className={classNames('date-picker__dates__date__label', {
										'date-picker__dates__date__label--active': isActive(
											date,
											granularity,
											selectedDate[0]
										),
										'date-picker__dates__date__label--passed':
											isPassed(date.dateAsDate, granularity) &&
											!isActive(date, granularity, selectedDate[0]),
									})}
								>
									{date.dateAsNumber}
								</label>
							</button>
							{/* <StatusDot date={date.dateAsDate} page={page} /> */}
						</div>
					);
				})}
			</div>
			<div
				className="date-picker__arrow-right"
				onClick={() => setDateSpan(spanForward(granularity, dateSpan))}
			>
				<Icon name="Path" width={10} />
			</div>
		</div>
	);
};
