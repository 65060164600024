import { Granularity } from '../../../../Enums';
import { DateAsValue } from './DatePickerUtils';
import { isSameDay, startOfWeek } from 'date-fns';

/**
 * checks if the selected week is the same as the current week
 * @param date the week to check
 * @returns true if current week is selected
 */
export const isActive = (
  date: DateAsValue,
  granularity: Granularity,
  selectedDate: Date
): boolean => {
  if (granularity === Granularity.WEEK) {
    return isSameDay(
      startOfWeek(selectedDate, { weekStartsOn: 1 }),
      startOfWeek(date.dateAsDate, { weekStartsOn: 1 })
    );
  }
  return isSameDay(date.dateAsDate, selectedDate);
};
