import { Granularity, Pages } from '../../../Enums';

export const getGranularity = (page: Pages): Granularity => {
  switch (page) {
    case Pages.PRODUCTION_PAGE:
      return Granularity.DAY;
    case Pages.TEMPLATE_PAGE:
      return Granularity.DAY;
    case Pages.WASTE_PAGE:
      return Granularity.DAY;
    case Pages.ORDERS_PAGE:
      return Granularity.WEEK;
    default:
      return Granularity.WEEK;
  }
};
