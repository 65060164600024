import { TemplateEntry } from '../../Core/SharedTypes/Models';

export const getRowsForUpdate = (
	templateValues: TemplateEntry[],
	productId: number,
	newEntries: TemplateEntry
) => {
	//find the affected row
	const newRow = templateValues.filter(
		(entry) => entry.productId === productId
	);

	//Ammend the affected row
	newRow[newRow.findIndex((entry) => entry.weekDay === newEntries.weekDay)] =
		newEntries;

	//Remove affected row from template
	const cutAffectedRow = templateValues.filter(
		(entry) => entry.productId !== productId
	);

	//Insert ammended row to template
	const newTemplate = cutAffectedRow.concat(newRow);

	return newTemplate;
};
