import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Location } from '../../../Core/SharedTypes/Models';

type GetSessionInfo = {
	csrfTokenValue: string;
	email: string;
	id: number;
	isGuest: boolean;
	uid: string;
	username: string;
};

type GetUserResponse = {
	id: number;
	locationType: any;
	relatedLocations: any[];
};

export const loginAxios = async (email: string, password: string) => {
	const session = await getSessionInfo().then((x) => {
		return x;
	});

	const formDataLogin = new FormData();
	formDataLogin.append('action', 'users/login');
	formDataLogin.append('loginName', email);
	formDataLogin.append('password', password);
	formDataLogin.append('CRAFT_CSRF_TOKEN', session.csrfTokenValue);

	return axios.post(document.location.href, formDataLogin);
};

export const logOutUser = async () => {
	axios('/logout');
};

export const useGetUser = (enabled = true) => {
	return useQuery(
		['user'],
		async () => {
			return await axios<GetUserResponse>(`/api/users`, {
				headers: { Accept: 'application/json' },
				// A 403 is a valid status, handled in the select below
				validateStatus: (status)  => (status >= 200 && status < 300 ) || status === 403,
			});
		},
		{
			enabled,
			select: ({ data, status }) => {
				// If there somehow is no data or if we are unauthenticated, we have no user
				if (!data || status === 403) {
					return null;
				}

				const relatedLocations: Location[] =
					data.relatedLocations.map((location) => {
						return {
							id: location.id,
							locationType: location.locationType.value,
							title: location.title,
						};
					}) || [];
				const uid = data.id;
				const locationType = data.locationType;
				return { relatedLocations, locationType, uid };
			},
			staleTime: 10 * (60 * 1000),
			cacheTime: 60 * (60 * 1000),
		}
	);
};

const getSessionInfo = async function (): Promise<GetSessionInfo> {
	return fetch('/actions/users/session-info', {
		headers: {
			Accept: 'application/json',
		},
	}).then((r) => r.json());
};
