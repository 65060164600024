import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { never } from 'zod';
import { Icon } from '../../Icon/Icon';
import classNames from 'classnames';
import './LoaderButton.scss';

export type Props = {
	idleText?: string;
	loadingText?: string;
	errorText?: string;
	onClick?: () => void;
	state: 'idle' | 'loading' | 'error';
	style?: CSSProperties;
};
/**
 *  A simple button for clicking
 */
export const LoaderButton: FC<Props> = ({
	idleText = 'Opdatér',
	loadingText = "Henter",
	errorText = "Fejl",
	onClick = () => {},
	state,
	style = {},
}) => {
	// Get the correct button text based on the state
	const buttonText = useMemo(() => {
		switch (state) {
			case 'idle':
				return idleText;
			case 'loading':
				return loadingText;
			case 'error':
				return errorText;
			default:
				never(state);
		}
	}, [errorText, idleText, loadingText, state]);

	// Determine if the clickhandler should be enabled
	const isClickEnabled = useMemo(
		() => ['idle', 'error'].includes(state),
		[state]
	);

	// Wrapper for the click handler to use enabled/disabled logic
	const handleClick = useCallback(() => {
		if (!isClickEnabled) {
			return;
		}

		onClick();
	}, [isClickEnabled, onClick]);

	return (
		<button
			className={classNames({ LoaderButton: true, isLoading: !isClickEnabled })}
			onClick={handleClick}
			style={style}
		>
			{(['loading', 'idle'].includes(state)) && (
				<div className={classNames({
					'LoaderButton__Icon': true,
					'LoaderButton__Icon--Loader': state ==='loading',
				})}>
					<Icon name="IconReload" width={20}  />
				</div>
			)}

			<div className="LoaderButton__Label">{buttonText}</div>
		</button>
	);
};
