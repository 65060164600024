export enum Pages {
  WASTE_PAGE = 'WASTE_PAGE',
  TEMPLATE_PAGE = 'TEMPLATE_PAGE',
  ORDERS_PAGE = 'ORDERS_PAGE',
  PRODUCTION_PAGE = 'PRODUCTION_PAGE',
  LOGIN_PAGE = 'LOGIN_PAGE',
}

export enum Granularity {
  WEEK = 'WEEK',
  DAY = 'DAY',
}

//!These will be loaded in from the backend at some point
export enum LocationTypes {
  PRODUCTION = 'PRODUCTION',
  SALESPOINT = 'SALESPOINT',
}
//!These will be loaded in from the backend at some point
export enum ProductType {
  FRANSKBROD = 'FRANSKBROD',
  RUGBROD = 'RUGBROD',
  PITABROD = 'PITABROD',
}
