import { CSSProperties, FC, useMemo } from 'react';
import './Spinner.scss';

interface Props {
	color: string;
	size: number;
	lineWidth: number;
}

// More Pure CSS loading can be found at:
// https://loading.io/css/

export const Spinner: FC<Props> = ({ color, size, lineWidth }) => {
	const wrapperStyle: CSSProperties = useMemo(
		() => ({
			width: `${size}px`,
			height: `${size}px`,
		}),
		[size]
	);

	const divStyle: CSSProperties = useMemo(() => {
		const sideLength = size - 2 * lineWidth;

		return {
			color,
			width: `${sideLength}px`,
			height: `${sideLength}px`,
			margin: `${lineWidth}px`,
			borderWidth: `${lineWidth}px`,
		};
	}, [color, lineWidth, size]);

	return (
		<div className="Spinner" style={wrapperStyle}>
			<div style={divStyle}></div>
			<div style={divStyle}></div>
			<div style={divStyle}></div>
			<div style={divStyle}></div>
		</div>
	);
};
