import { Location } from "../../../../SharedTypes/Models";

export const locationTitle = (
  locations: Location[],
  selectedLocation: number
): string => {
  if (!locations) {
    return "Fejl";
  }
  return (
    locations.find((location) => location.id === selectedLocation)?.title ||
    "Fejl"
  );
};
