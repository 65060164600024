import { Spinner } from '../Spinner/Spinner';

import './LoadingTable.scss';

// More Pure CSS loading can be found at:
// https://loading.io/css/

export const LoadingTable = () => (
	<div className="LoadingTable">
		<Spinner size={70}  lineWidth={8} color="#cfb170" />
	</div>
);
