import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useState,
} from 'react';

export const UserContext = createContext<
  [number, Dispatch<React.SetStateAction<number>>]
>([0, () => {}]);

export const UserContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [selectedUser, setSelectedUser] = useState<number>(0);

  return (
    <UserContext.Provider value={[selectedUser, setSelectedUser]}>
      {children}
    </UserContext.Provider>
  );
};
