import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Template, TemplateEntry } from '../SharedTypes/Models';

const API_URL = '/api/templates/';

export function useGetTemplate(locationId: number) {
	return useQuery({
		queryKey: ['template', { locationId }],
		queryFn: () => {
			return axios(API_URL + locationId);
		},
		select: (data) => {
			if (data) {
				if (data.data.result[0]) {
					const Template: Template = data.data.result[0];
					const templateValues: TemplateEntry[] =
						Object.values(JSON.parse(data?.data?.result[0]?.templateValues)) || [];
					return { ...Template, templateValues };
				}
			}
		},
	});
}

export function useUpdateTemplate() {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: (template: Template) =>
			axios.put<Template>(API_URL, {
				...template,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['template'],
			});
			queryClient.invalidateQueries({
				queryKey: ['week-entries'],
			});
		},
	});
	return mutation;
}
