import { useContext } from 'react';
import { Icon } from '../Icon/Icon';
import './Filter.scss';
import * as Popover from '@radix-ui/react-popover';
import { ProductionFilterContext } from '../../Contexts/ProductionFilterContext';
import { Checkbox } from '../Checkbox/Checkbox';
import classNames from 'classnames';

export const Filter = ({
	locations,
}: {
	locations: { locationId: string; locationName: string }[];
}) => {
	const [selectedLocations, setSelectedLocations] = useContext(
		ProductionFilterContext
	);

	const handleToggleLocation = (locationId: string) => {
		if (selectedLocations.includes(locationId)) {
			setSelectedLocations(selectedLocations.filter((id) => id !== locationId));
		} else {
			setSelectedLocations([...selectedLocations, locationId]);
		}
	};

	const toggleAll = () => {
		setSelectedLocations(
			locations.map((location) => location.locationId.toString())
		);
	};

	const clearAll = () => {
		setSelectedLocations([]);
	};

	const filterActive = selectedLocations.length !== locations.length;

	return (
		<Popover.Root>
			<Popover.Trigger asChild>
				<button className="IconButton">
					<Icon name="Filter" width={22} />
					{filterActive && <span className="Filter__Active"></span>}
				</button>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content className="PopoverContent" sideOffset={5}>
					<div className="Filter">
						<div className="Filter__Header">
							<h2 className="Filter__Title">Afdeling</h2>
							<div className="Filter__Header-Actions">
								<button onClick={() => toggleAll()}>Vælg alle</button>
								<button onClick={() => clearAll()}>Ryd</button>
							</div>
						</div>
						<div className="Filter__Locations">
							{locations.map((location) => (
								<label
									key={location.locationId}
									className={classNames('Filter__Form-Element', {
										'Filter__Form-Element--Checked': selectedLocations.includes(
											location.locationId.toString()
										),
									})}
									onClick={() =>
										handleToggleLocation(location.locationId.toString())
									}
								>
									<Checkbox
										checked={selectedLocations.includes(
											location.locationId.toString()
										)}
									/>
									{location.locationName}
								</label>
							))}
						</div>
					</div>
					{/* <Popover.Close className="PopoverClose" aria-label="Close">
						<Icon name="CombinedShape" width={20} />
					</Popover.Close> */}
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
};
