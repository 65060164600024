import { createContext, Dispatch, FC, PropsWithChildren } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const LocationContext = createContext<
	[number, Dispatch<React.SetStateAction<number>>]
>([0, () => {}]);

export const LocationContextProvider: FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	const [selectedLocation, setSelectedLocation] = useLocalStorage(
		'selectedLocation',
		0
	);

	return (
		<LocationContext.Provider value={[selectedLocation, setSelectedLocation]}>
			{children}
		</LocationContext.Provider>
	);
};
