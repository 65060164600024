import { FC, useCallback, useEffect, useRef } from 'react';
import { ProductEntryFromWeek } from '../../Core/Hooks/EntryHooks';
import { ProductEntry, WeekHeaders } from '../../Core/SharedTypes/Models';
import { getProductCategories } from '../../Core/Utils/GetProductCategories';
import { dateSpan } from './utils/getWeeksForSubtitle';
import { MemoizedTableCell } from '../../Core/Components/TableComponents/TableCell/TableCell';
import { isPassed } from '../../Core/Components/Inputs/DatePicker/utils/isPassed';
import { Granularity } from '../../Core/Enums';
import { useStickyTable } from 'react-sticky-table-hook';
import classNames from 'classnames';
import { useScrolled } from '../../Core/Hooks/useScrolled';
import { getRowsForTable } from './utils/getRowsForTable';
import { LoadingTable } from '../../Core/Components/LoadingTable/LoadingTable';
import { Location } from '../../Core/SharedTypes/Models';

export interface Props {
	selectedDate: Date;
	currentLocation: Location | undefined;
	saveEntry: (entry: ProductEntry) => void;
	resetSave: () => void;
	entries: ProductEntryFromWeek[] | undefined;
	isLoading: boolean;
	isSaveError: boolean;
}

export const OrdersTable: FC<Props> = ({
	currentLocation,
	entries,
	isLoading,
	saveEntry,
	resetSave,
	selectedDate,
	isSaveError,
}) => {
	const currentWeek = dateSpan(selectedDate);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isScrolled] = useScrolled(scrollRef);

	const tableConfig = useStickyTable({
		columns: [
			{
				width: 'minmax(auto, 2fr)',
				isSticky: true,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
		],
	});

	const isPast = (date: Date, granularity: Granularity) =>
		isPassed(date, granularity);

	const productCategories = getProductCategories(
		currentLocation?.products ?? []
	);

	const handleChange = useCallback(
		(entry: ProductEntry) => (newOrdered: number) =>
			saveEntry({ ...entry, ordered: newOrdered }),
		[saveEntry]
	);

	const tableRows = useCallback(
		(productId: number) => getRowsForTable(productId, entries),
		[entries]
	);

	// Used to reset the error state in saveEntry mutation
	useEffect(() => {
		if (isSaveError) {
			setTimeout(() => {
				resetSave();
			}, 1000);
		}
	}, [isSaveError, resetSave]);

	return (
		<div
			className="table table__scrollable"
			style={{ ...tableConfig.table.style }}
			ref={scrollRef}
		>
			{isLoading && <LoadingTable />}
			{!isLoading &&
				productCategories.map(({ title, id: categoryId }) => (
					<div
						key={categoryId}
						style={{ paddingBottom: '1rem', width: 'fit-content' }}
					>
						<div
							style={{
								...tableConfig.row.sticky.style,
								borderBottom: '1px solid #E3E0D4',
								width: '100%',
							}}
						>
							{[title, ...WeekHeaders].map((header, index) => {
								return (
									<div
										key={header}
										style={{
											...tableConfig.cells.sticky[index].style,
											minWidth: index === 0 ? '200px' : '120px',
										}}
										className={classNames(['table__header__cell'], {
											'table__header__cell--shadowed':
												isScrolled && index === 0,
										})}
									>
										<p
											className={classNames(['table__header__cell__data'], {
												'table__header__cell__data--title': index === 0,
												'table__header__cell__data--is-past': isPast(
													currentWeek[index - 1],
													Granularity.DAY
												),
											})}
										>
											{header}
										</p>
										{index === 0 && <div style={{ opacity: 0 }}> Date </div>}
										{index > 0 && (
											<p
												className={classNames(
													['table__header__cell__data__date-string'],
													{
														'table__header__cell__data--is-past': isPast(
															currentWeek[index - 1],
															Granularity.DAY
														),
													}
												)}
											>
												{currentWeek[index - 1].getDate() < 10 ? '0' : ''}
												{currentWeek[index - 1].getDate()}.
												{currentWeek[index - 1].getMonth() + 1 < 10 ? '0' : ''}
												{currentWeek[index - 1].getMonth() + 1}
											</p>
										)}
									</div>
								);
							})}
						</div>
						{currentLocation?.products?.map(
							({
								productCategory,
								id: productId,
								title: ProductTitle,
								amountPerParcel,
							}) => {
								if (productCategory[0].id === categoryId) {
									return (
										<div
											key={productId + ProductTitle}
											style={{
												...tableConfig.row.scrolling.style,
												borderBottom: '1px solid #E3E0D4',
											}}
											className="table__row"
										>
											<div
												style={{
													...tableConfig.cells.scrolling[0].style,
													borderRight: '1px solid #E3E0D4',
													minWidth: '200px',
												}}
												className={classNames(
													['table__cell', 'table__cell--title'],
													{
														'table__cell--shadowed': isScrolled,
													}
												)}
											>
												{ProductTitle}
											</div>
											{entries &&
												tableRows(productId).map((entry, cellNumber) => {
													return (
														<MemoizedTableCell
															key={entry.id}
															style={{
																...tableConfig.cells.scrolling?.[cellNumber + 1]
																	?.style,
																minWidth: '120px',
															}}
															isEditable={entry.status === 'unlocked'}
															isPassed={isPassed(entry.date, Granularity.DAY)}
															isLocked={entry.status === 'locked'}
															isTotal={false}
															value={entry.ordered}
															handleChange={handleChange(entry)}
															amountPerParcel={amountPerParcel}
															isSaveError={isSaveError}
														/>
													);
												})}
										</div>
									);
								}
								return null;
							}
						)}
					</div>
				))}
		</div>
	);
};
