import { TemplateEntry } from '../../Core/SharedTypes/Models';

export const getTemplateEntries = (
  productId: number,
  templateValues: TemplateEntry[]
): TemplateEntry[] => {
  if (!templateValues) {
    return [];
  }
  const result = templateValues.filter(
    (templateEntry) => templateEntry.productId === productId
  );
  return result;
};
