import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../../../../LoginPage/UserForm/Hooks/LoginHook';
import { Icon } from '../../Icon/Icon';
import './NavButton.scss';

type Props = {
	title: string;
	icon:
		| 'Path'
		| 'TrashIcon'
		| 'Seal'
		| 'Carret'
		| 'Logout'
		| 'Production'
		| 'Login'
		| 'Normal'
		| 'OrderingIcon'
		| 'TemplateIcon';
	isActive?: boolean;
	path: string;
};

export const NavButton: FC<Props> = ({
	title,
	icon,
	isActive = false,
	path,
}) => {
	const navigate = useNavigate();
	const handleLogout = () => {
		logOutUser();
	};
	return (
		<div
			onClick={() => {
				if (path === '/authenticate') {
					handleLogout();
				}
				navigate(path);
			}}
			className={classNames('nav-button', {
				'nav-button--active': isActive,
				/** This is a hack - We should rework this logic */
				'nav-button--logout': path === '/authenticate',
			})}
		>
			<Icon name={icon} width={18} />
			<label className="nav-button__title">{title}</label>
		</div>
	);
};

export default NavButton;
