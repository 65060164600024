import { useGetLocation } from '../Core/Hooks/LocationHooks';
import { useCallback, useContext, useMemo } from 'react';
import { LocationContext } from '../Core/Contexts/LocationContext';
import { useGetTemplate, useUpdateTemplate } from '../Core/Hooks/TemplateHooks';
import { Props } from './TemplateTable';

type AdditionalProps = {
	isFetching: boolean;
	isSaving: boolean;
	refresh: () => Promise<void>;
};

export const useTemplateTableData = (): Props & AdditionalProps => {
	const [selectedLocation] = useContext(LocationContext);
	const {
		data: currentLocation,
		isLoading: isLoadingLocation,
		isFetching: isFetchingLocation,
		refetch: refetchLocation,
	} = useGetLocation(selectedLocation);
	const {
		data: templateData,
		isLoading: isLoadingTemplate,
		isFetching: isFetchingTemplate,
		refetch: refetchTemplate,
	} = useGetTemplate(selectedLocation);
	const { mutate: saveTemplate, isLoading: isSaving } = useUpdateTemplate();

	const isLoading = useMemo(
		() => isLoadingLocation || isLoadingTemplate,
		[isLoadingLocation, isLoadingTemplate]
	);
	const isFetching = useMemo(
		() => isFetchingLocation || isFetchingTemplate,
		[isFetchingLocation, isFetchingTemplate]
	);

	const refresh = useCallback(() => {
		return (
			Promise.all([
				refetchLocation(),
				refetchTemplate(),
			])
				// We want to return void
				.then(() => {})
		);
	}, [refetchLocation, refetchTemplate]);

	return {
		currentLocation,
		templateData,
		isLoading,
		isFetching,
		isSaving,
		saveTemplate,
		refresh,
	};
};
