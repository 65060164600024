import { Pages } from '../Core/Enums';
import { FooterContainer } from '../Core/Components/Footer/Footer';
import './ProductionPage.scss';
import { Header } from '../Core/Components/Header/Header';
import { DatePicker } from '../Core/Components/MiniDatePicker/MiniDatePicker';
import { Printable } from '../Core/Components/Printable/Printable';
import {
	LoaderButton,
	Props as LoaderButtonProps,
} from '../Core/Components/Inputs/LoaderButton/LoaderButton';
import { useNewProductionData } from './productionTableDataHook';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ProductionTableNew } from './ProductionTableNew';
import { LoadingTable } from '../Core/Components/LoadingTable/LoadingTable';
import { Filter } from '../Core/Components/Filter/Filter';
import { ProductionFilterContext } from '../Core/Contexts/ProductionFilterContext';

export const ProductionPage = () => {
	const [, setSelectedLocationIds, initialized] = useContext(
		ProductionFilterContext
	);
	// Get the data for our table
	const tableData = useNewProductionData();

	// Get the state for the refresh button
	const refreshButtonState: LoaderButtonProps['state'] = useMemo(() => {
		return tableData.isFetching ? 'loading' : 'idle';
	}, [tableData.isFetching]);

	/** Running only if initialized is false */
	useEffect(() => {
		if (tableData && tableData.data && initialized === false) {
			setSelectedLocationIds(
				tableData.data.locations.map((location) =>
					location.locationId.toString()
				)
			);
		}
	}, [tableData, initialized, setSelectedLocationIds]);

	// Create the handler for refreshing data
	const handleRefresh = useCallback(() => {
		return async () => {
			tableData.refetch();
		};
	}, [tableData]);

	return (
		<div className="production-page">
			<div className="production-page__header">
				<Header
					page={Pages.PRODUCTION_PAGE}
					button={
						<LoaderButton
							idleText="Opdatér"
							errorText="Fejl"
							state={refreshButtonState}
							onClick={handleRefresh()}
						/>
					}
				>
					{tableData.data?.locations && (
						<Filter locations={tableData.data?.locations} />
					)}
					{tableData.data && (
						<Printable
							printableComponent={
								<ProductionTableNew productionData={tableData.data} />
							}
							page={Pages.PRODUCTION_PAGE}
							onBeforePrint={handleRefresh()}
							isLoading={tableData.isFetching}
						/>
					)}
					<DatePicker withRange={true} />
				</Header>
			</div>
			<div className="production-page__content">
				{tableData.isLoading && <LoadingTable />}
				{tableData.data && (
					<ProductionTableNew productionData={tableData.data} />
				)}
			</div>
			<div className="production-page__footer">
				<FooterContainer page={Pages.PRODUCTION_PAGE} />
			</div>
		</div>
	);
};
