import classNames from 'classnames';
import {
	ChangeEvent,
	CSSProperties,
	FC,
	memo,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useDebounce } from 'usehooks-ts';
import { Product } from '../../../SharedTypes/Models';
import { Icon } from '../../Icon/Icon';
import './TableCell.scss';

export type Props = {
	isEditable: boolean;
	isLocked: boolean;
	isPassed?: boolean;
	isBold?: boolean;
	handleBlur?: any;
	handleFocus?: any;
	children?: ReactNode;
	isTotal: boolean;
	isBakeoffTotal?: boolean;
	value: number;
	handleChange?: (args: number) => void;
	style?: CSSProperties;
	isBakeoff?: Product['isBakeoff'];
	amountPerParcel?: Product['amountPerParcel'];
	isSaveError?: boolean;
};

export const TableCell: FC<Props> = ({
	isEditable = false,
	isTotal = false,
	isBakeoffTotal = false,
	isLocked = false,
	isPassed = false,
	handleChange,
	value,
	style,
	isBakeoff,
	amountPerParcel,
	isSaveError,
}) => {
	const [fieldValue, setValue] = useState<number | null>(null);
	const debouncedValue = useDebounce(fieldValue, 1000);

	const changeOnServer = useCallback(
		(newValue: number) => {
			if (!handleChange || newValue === null || newValue === undefined) {
				return;
			}

			if (newValue === value) {
				return;
			}

			return handleChange(newValue);
		},
		[handleChange, value]
	);

	useEffect(() => {
		if (isSaveError && fieldValue !== value) {
			setValue(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSaveError, value]);

	// A somewhat annoying way to use a debounced value
	useEffect(() => {
		if (!changeOnServer) {
			return;
		}

		if (debouncedValue !== null && debouncedValue !== value) {
			//		If the amountPerParcel is 1 or nullish, just set the value as is
			if (!amountPerParcel || amountPerParcel === 1) {
				changeOnServer(debouncedValue);
				return;
			}

			const correctedValue =
				Math.ceil(debouncedValue / amountPerParcel) * amountPerParcel;

			// Publish the modified value
			changeOnServer(correctedValue);

			// Update the local field too (optimistic change)
			setValue(correctedValue);
		}
	}, [amountPerParcel, debouncedValue, changeOnServer, value]);

	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const parsedValue = Number(event.target.value);
		if (!isNaN(parsedValue)) {
			setValue(parsedValue);
		}
	};

	const showIcon = () => {
		if (isLocked) {
			return <Icon className="no-print" name="Lock" width={22} />;
		}

		if (isBakeoffTotal || (isBakeoff === false && value > 0)) {
			return <Icon className="print" name="IconHeat" width={22} />;
		}

		return null;
	};

	return (
		<div
			data-testid="TableCell"
			className={classNames(['table-cell', 'table__cell'], {
				'table-cell--total': isTotal,
				'table-cell--editable': isEditable && !isLocked && !isTotal,
				'table-cell--greyed': isLocked || isPassed,
			})}
			style={style}
		>
			<div className="table-cell__wrapper">
				<input
					data-testid="TableCellInput"
					className={classNames('table-cell__input', {
						'table-cell__input--editable': !isLocked && !isPassed && isEditable,
					})}
					value={fieldValue ?? value}
					readOnly={isLocked || isPassed || !isEditable}
					onChange={onChangeHandler}
				></input>
				<div className="table-cell__lock">{showIcon()}</div>
			</div>
		</div>
	);
};

const arePropsEqual = (prev: Props, next: Props) => {
	if (prev.value !== next.value || prev.isSaveError !== next.isSaveError) {
		return false;
	}

	return true;
};

export const MemoizedTableCell = memo(TableCell, arePropsEqual);
