import {
	createContext,
	Dispatch,
	FC,
	PropsWithChildren,
	useState,
} from 'react';

export const DateContext = createContext<
	[[Date, Date | null], Dispatch<React.SetStateAction<[Date, Date | null]>>]
>([[new Date(), new Date()], () => {}]);

export const DateContextProvider: FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	const [selectedDate, setSelectedDate] = useState<[Date, Date | null]>([
		new Date(),
		new Date(),
	]);

	return (
		<DateContext.Provider value={[selectedDate, setSelectedDate]}>
			{children}
		</DateContext.Provider>
	);
};
