import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { DateContext } from '../Core/Contexts/DateContext';
import { LocationContext } from '../Core/Contexts/LocationContext';
import { useGetProductionEntries } from '../Core/Hooks/EntryHooks';
import {
	useGetLocation,
	useGetLocationsByType,
	LocationType,
} from '../Core/Hooks/LocationHooks';
import { ProductionData } from '../Core/SharedTypes/Models';
import { getProductCategories } from '../Core/Utils/GetProductCategories';
import { Props } from './ProductionTable';

interface AdditionalProps {
	isFetching: boolean;
	refresh: () => Promise<void>;
}

export const useProductionTableData = (): Props & AdditionalProps => {
	const [selectedDate] = useContext(DateContext);
	const [selectedLocation] = useContext(LocationContext);

	/** Gets the config for the current location */
	const {
		data: currentLocation,
		isLoading: areLocationsLoading,
		refetch: refetchCurrentLocation,
		isFetching: areLocationsFetching,
	} = useGetLocation(selectedLocation);

	/** Gets all the sales locations */
	const {
		data: salesLocations,
		isLoading: areSalesLocationsLoading,
		refetch: refetchSalesLocations,
		isFetching: areSalesLocationsFetching,
	} = useGetLocationsByType(LocationType.sales);

	const allProducts = currentLocation?.products ?? [];

	const {
		data: entries,
		isLoading: areEntriesLoading,
		refetch: refetchProductionEntries,
		isFetching: areEntriesFetching,
	} = useGetProductionEntries(selectedDate[0]);

	/** Extract product categories that this production site has access to */
	const productCategories = getProductCategories(allProducts);

	const isInitialLoading = useMemo(
		() => areEntriesLoading || areLocationsLoading || areSalesLocationsLoading,
		[areEntriesLoading, areLocationsLoading, areSalesLocationsLoading]
	);

	const isFetching = useMemo(
		() =>
			areEntriesFetching || areSalesLocationsFetching || areLocationsFetching,
		[areEntriesFetching, areLocationsFetching, areSalesLocationsFetching]
	);

	const refresh = useCallback(() => {
		return (
			Promise.all([
				refetchSalesLocations(),
				refetchCurrentLocation(),
				refetchProductionEntries(),
			])
				// We want to return void
				.then(() => {})
		);
	}, [refetchCurrentLocation, refetchProductionEntries, refetchSalesLocations]);

	return {
		salesLocations,
		entries,
		selectedDate: selectedDate[0],
		isInitialLoading,
		isFetching,
		productCategories,
		allProducts,
		refresh,
	};
};

const API_URL = '/api/production';

export const useNewProductionData = () => {
	const [[startDate, endDate]] = useContext(DateContext);
	const [selectedLocation] = useContext(LocationContext);

	function hasValidDateRange() {
		return startDate && endDate ? true : false;
	}

	const formattedStartDate = format(startDate, 'yyyy-MM-dd');
	const formattedEndDate = format(endDate ?? startDate, 'yyyy-MM-dd');

	return useQuery(
		[
			'new-production',
			{
				locationId: selectedLocation,
				startDate: formattedStartDate,
				endDate: formattedEndDate,
			},
		],
		() => {
			const request = `${API_URL}/${formattedStartDate}/${formattedEndDate}/${selectedLocation}`;
			return axios<{ result: ProductionData }>(request);
		},
		{
			select: (resultData) => resultData.data.result,
			enabled: hasValidDateRange(),
			refetchInterval: 1000 * 60 * 5, // Poll every 5 minutes
		}
	);
};
