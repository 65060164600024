import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Location } from '../SharedTypes/Models';

const ELEMENT_URL: string = '/api/locations';
const ELEMENT_URL_SINGLE: string = '/api/location';

/** Queries the current users locations */
export const useGetMyLocations = () =>
	useQuery({
		queryKey: ['my-locations'],
		queryFn: () => axios<{ result: Location[] }>(ELEMENT_URL),
		select: (result) => result.data.result,
		staleTime: 2 * (60 * 1000),
		cacheTime: 10 * (60 * 1000),
	});

export enum LocationType {
	sales = 'sales',
	production = 'production',
	all = 'all',
}

export const useGetLocationsByType = (locationType: LocationType) =>
	useQuery({
		queryKey: ['location-by-type', { locationType }],
		queryFn: () =>
			axios<{ result: Location[] }>(ELEMENT_URL + `/${locationType}`),
		select: (result) => result.data.result,
		staleTime: 1 * (60 * 1000),
		cacheTime: 10 * (60 * 1000),
	});

export const useGetLocation = (locationId: number) =>
	useQuery({
		queryKey: ['location', { locationId }],
		queryFn: () => axios(ELEMENT_URL_SINGLE + `/${locationId}`),
		select: (result) => {
			const queryData: Location = result.data.result;

			return queryData;
		},
		staleTime: 2 * (60 * 1000),
		cacheTime: 10 * (60 * 1000),
	});
