import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import axios from 'axios';

import { ProductEntry } from '../SharedTypes/Models';

const API_URL = '/api/entries/';

export function useGetProductEntriesFromDate(locationId: number, date: string) {
	return useQuery(
		['entries', { locationId, date }],
		async () => {
			const request = `${API_URL}waste/${locationId}/${date}`;

			return await axios<{ result: ProductEntry[] }>(request);
		},
		{
			select: ({ data }) => {
				if (!data) {
					return [];
				}

				const result = data.result.map((productEntry) => {
					return {
						...productEntry,
						productId: Number(productEntry.productId),
						date: new Date(productEntry.date),
					};
				});
				return result;
			},
		}
	);
}

export type ProductEntryFromWeek = ProductEntry & {
	productId: number;
	date: Date;
};

export function useGetProductEntriesFromWeek(
	startDate: string,
	endDate: string,
	locationId: number
) {
	return useQuery({
		queryKey: ['week-entries', { locationId, startDate, endDate }],
		queryFn: async () => {
			const request = `${API_URL}/orders/${startDate}/${endDate}/${locationId}`;
			return await axios<{ result: ProductEntry[] }>(request);
		},
		select: ({ data }): ProductEntryFromWeek[] => {
			if (!data) {
				return [];
			}
			return data.result.map((productEntry) => {
				return {
					...productEntry,
					productId: Number(productEntry.productId),
					date: new Date(productEntry.date),
				};
			});
		},
	});
}

export function useGetProductionEntries(date: Date) {
	return useQuery(
		['production-entries', { date: format(date, 'yyyy-MM-dd') }],
		() => {
			const formattedDate = format(date, 'yyyy-MM-dd');

			const request = `${API_URL}production/${formattedDate}`;

			return axios<{ result: ProductEntry[] }>(request);
		},
		{
			select: ({ data }) => {
				if (!data) {
					return [];
				}
				return (
					data.result.map((productEntry) => {
						return {
							...productEntry,
							productId: Number(productEntry.productId),
							date: new Date(productEntry.date),
						};
					}) || []
				);
			},
		}
	);
}

export const useUpdateEntries = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async (entry: ProductEntry) => {
			const result = await axios.put(API_URL, {
				...entry,
			});

			queryClient.invalidateQueries(['entries']);
			queryClient.invalidateQueries(['waste-status']);
			queryClient.invalidateQueries(['order-status']);
			queryClient.invalidateQueries(['production-entries']);

			// THIS IS A HACK
			// It ensures we don't invalidate on every keystroke, and we cannot easily debounce due to bad structure in component...
			setTimeout(() => queryClient.invalidateQueries(['week-entries']), 700);

			return result;
		},
		{
			networkMode: 'always',
			retry: false,
			cacheTime: 1100,
		}
	);
};
