export type Status = 'locked' | 'unlocked';
export type LocationType = 'sales' | 'production';
export type EditStatus = 'reported' | 'partial' | 'none';

export const WeekHeaders = [
	'Mandag',
	'Tirsdag',
	'Onsdag',
	'Torsdag',
	'Fredag',
	'Lørdag',
	'Søndag',
];
export type Location = {
	title: string;
	locationType: LocationType;
	products?: Product[];
	id: number;
};

export type ProductCategory = {
	id: number;
	title: string;
};

export type Product = {
	id: number;
	productCategory: ProductCategory[];
	title: string;
	isBakeoff: boolean;
	amountPerParcel: number | null;
};

export type ProductEntry = {
	id: number;
	uid: string;
	status: Status;
	ordered: number;
	sold: number;
	remaining: number | null;
	waste: number;
	locationId: number;
	productId: number;
	date: Date;
	isReported: boolean;
	templateValue: number;
};

export type UserData = {
	uid: string;
	fullName: string;
};

export type Template = {
	uid: string;
	templateValues: TemplateEntry[];
	locationId: number;
};

export type TemplateEntry = {
	weekDay: number;
	value: number;
	productId: number;
};

export type ProductionData = {
	locations: Array<{
		locationId: string;
		locationName: string;
	}>;
	categories: Array<{
		categoryId: string;
		categoryName: string;
		products: Array<{
			productId: string;
			productName: string;
			entries: Array<{
				entryId: string;
				entryDate: string;
				amount: number;
				isBakeoff: boolean;
				locationId: string;
			}>;
		}>;
	}>;
};
