import { useCallback, useContext, useMemo } from 'react';
import { DateContext } from '../../Core/Contexts/DateContext';
import { LocationContext } from '../../Core/Contexts/LocationContext';
import {
	useUpdateEntries,
	useGetProductEntriesFromWeek,
} from '../../Core/Hooks/EntryHooks';
import { useGetLocation } from '../../Core/Hooks/LocationHooks';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { Props } from './OrderTable';

interface AdditionalProps {
	isSaving: boolean;
	isFetching: boolean;
	isSaveError: boolean;
	resetSave: () => void;
	refresh: () => Promise<void>;
}

export const useOrdersTableData = (): Props & AdditionalProps => {
	const [selectedDate] = useContext(DateContext);
	const [selectedLocation] = useContext(LocationContext);
	const {
		data: currentLocation,
		isLoading: isLoadingLocation,
		isFetching: isFetchingLocation,
		refetch: refetchLocation,
	} = useGetLocation(selectedLocation);

	const {
		mutate: saveEntry,
		isLoading: isSaving,
		isError: isSaveError,
		reset: resetSave,
	} = useUpdateEntries();

	const {
		data: entries,
		isLoading: areProductEntriesLoading,
		isFetching: areProductEntriesFetching,
		refetch: refetchEntries,
	} = useGetProductEntriesFromWeek(
		format(startOfWeek(selectedDate[0], { weekStartsOn: 1 }), 'yyyy-MM-dd'),
		format(endOfWeek(selectedDate[0], { weekStartsOn: 1 }), 'yyyy-MM-dd'),
		selectedLocation
	);

	const isLoading = useMemo(
		() => areProductEntriesLoading || isLoadingLocation,
		[areProductEntriesLoading, isLoadingLocation]
	);

	const isFetching = useMemo(
		() => areProductEntriesFetching || isFetchingLocation,
		[areProductEntriesFetching, isFetchingLocation]
	);

	const refresh = useCallback(() => {
		return (
			Promise.all([refetchEntries(), refetchLocation()])
				// We want to return void
				.then(() => {})
		);
	}, [refetchEntries, refetchLocation]);

	return {
		selectedDate: selectedDate[0],
		currentLocation,
		saveEntry,
		entries,
		isLoading,
		isFetching,
		isSaving,
		isSaveError,
		resetSave,
		refresh,
	};
};
