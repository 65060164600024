import classNames from 'classnames';
import React, { FC } from 'react';
import { Noop } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import './TextField.scss';

type Props = {
	value: string;
	name: string;
	placeholderText: string;
	type: string;
	handleChange: (args: string) => void;
	theme?: 'default' | 'login';
	errorMessage?: string;
	onBlur?: Noop;
};

/**
 * A simple text field used for typing
 */
export const TextField: FC<Props> = ({
	value,
	name,
	placeholderText,
	type,
	handleChange,
	theme,
	errorMessage,
	onBlur = () => {},
}) => {
	const { value: focused, setTrue, setFalse } = useBoolean();
	return (
		<div className="TextInputContainer">
			<input
				className="TextInput"
				name={name}
				id={name}
				type={type}
				defaultValue={value}
				onChange={(event) => handleChange(event.target.value)}
				onFocus={setTrue}
				onBlur={() => {
					onBlur();
					setFalse();
				}}
			></input>
			<label
				className={classNames(['TextInputLabel', `TextInputLabel--${theme}`], {
					'TextInputLabel--active': focused,
					'TextInputLabel--dirty': !!value,
					'TextInputLabel--error': errorMessage,
				})}
				htmlFor={name}
			>
				{placeholderText}
			</label>
			{errorMessage && <p className="TextInputError">{errorMessage}</p>}
		</div>
	);
};
