import { FC, forwardRef, MouseEventHandler, useContext } from 'react';
import ReactDatePicker, {
	ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import { da } from 'date-fns/locale';
import './MiniDatePicker.scss';
import { DateContext } from '../../Contexts/DateContext';
import { Icon } from '../Icon/Icon';
import { format } from 'date-fns';

type Props = {
	withRange?: boolean;
};

export const DatePicker: FC<Props> = ({ withRange = false }) => {
	const [[startDate, endDate], setSelectedDate] = useContext(DateContext);

	function handleSetDate(date: Date | [Date | null, Date | null] | null) {
		if (Array.isArray(date) && date[0]) {
			// It's a range, with 2 valid dates
			setSelectedDate([date[0], date[1]]);
			return;
		}

		if (!Array.isArray(date) && date) {
			// It's a valid single date
			setSelectedDate([date, null]);
			return;
		}
	}

	const handleOnBlur = () => {
		// Check the dates in context, if both are valid - Do nothing
		if (startDate && endDate) {
			return;
		}

		if (startDate && !endDate) {
			setSelectedDate([startDate, startDate]);
			return;
		}
	};

	const InputElement = withRange ? (
		<DatePickerRangeInput startDate={startDate} endDate={endDate} />
	) : (
		<DatePickerButton />
	);

	return (
		<ReactDatePicker
			portalId="datepicker-portal"
			className="react-datepicker"
			selected={startDate}
			startDate={startDate}
			endDate={endDate}
			onChange={handleSetDate}
			onCalendarClose={handleOnBlur}
			locale={da}
			customInput={InputElement}
			showWeekNumbers={true}
			renderCustomHeader={DatePickerHeader}
			selectsRange={withRange}
		/>
	);
};

type ButtonProps = {
	onClick?: MouseEventHandler<HTMLButtonElement>;
};

const IconStyle: React.CSSProperties = {
	marginRight: '20px',
};

const DatePickerButton = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => (
		<button
			className="react-datepicker__button"
			onClick={props.onClick}
			ref={ref}
		>
			<Icon
				name="CombinedShape"
				width={22}
				key="CalendarIcon"
				style={IconStyle}
			/>
		</button>
	)
);

type DatePickerRangeInputProps = {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	startDate: Date;
	endDate: Date | null;
};

const DatePickerRangeInput = forwardRef<
	HTMLButtonElement,
	DatePickerRangeInputProps
>((props, ref) => {
	const formattedStartDate = format(props.startDate, 'dd LLL');
	const formattedEndDate = props.endDate
		? ` - ${format(props.endDate, 'dd LLL')}, ${format(props.endDate, 'yyyy')}`
		: '';

	return (
		<button
			className="react-datepicker__range"
			onClick={props.onClick}
			ref={ref}
		>
			<input
				className="react-datepicker__range__input"
				value={`${formattedStartDate}${formattedEndDate}`}
				onChange={() => {}}
			/>
			<Icon name="CombinedShape" width={22} key="CalendarIcon" />
		</button>
	);
});

const DatePickerHeader: FC<ReactDatePickerCustomHeaderProps> = (props) => (
	<div className="DatePickerHeader">
		<button
			onClick={props.decreaseMonth}
			className="DatePickerHeader__button DatePickerHeader__button--prev"
		>
			<Icon name="CaretLeftGold" width={9} />
		</button>

		<h1 className="DatePickerHeader__title">{`${
			MONTHS[props.date.getMonth()]
		} ${props.date.getFullYear()}`}</h1>

		<button
			onClick={props.increaseMonth}
			className="DatePickerHeader__button DatePickerHeader__button--next"
		>
			<Icon name="CaretLeftGold" width={9} />
		</button>
	</div>
);

const MONTHS = [
	'Januar',
	'Februar',
	'Marts',
	'April',
	'Maj',
	'Juni',
	'Juli',
	'August',
	'September',
	'Oktober',
	'November',
	'December',
];
