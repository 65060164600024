import { useStickyTable } from 'react-sticky-table-hook';
import classNames from 'classnames';
import { TableCell } from '../Core/Components/TableComponents/TableCell/TableCell';
import { FC, useEffect, useRef, useState } from 'react';
import {
	Template,
	TemplateEntry,
	WeekHeaders,
	Location,
} from '../Core/SharedTypes/Models';
import { getProductCategories } from '../Core/Utils/GetProductCategories';
import { getRowsForUpdate } from './utils/getRowsForUpdate';
import { getTemplateEntries } from './utils/getTemplateEntries';
import { useDebounce } from 'usehooks-ts';
import { useScrolled } from '../Core/Hooks/useScrolled';
import { LoadingTable } from '../Core/Components/LoadingTable/LoadingTable';

type TemplateStateType = {
	templateValues: TemplateEntry[];
	uid: string;
	locationId: number;
};

export type Props = {
	currentLocation: Location | undefined;
	templateData:
		| {
				templateValues: TemplateEntry[];
				uid: string;
				locationId: number;
		  }
		| undefined;
	isLoading: boolean;
	saveTemplate: (template: Template) => void;
};

export const TemplateTable: FC<Props> = ({
	currentLocation,
	isLoading,
	saveTemplate,
	templateData,
}) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isScrolled] = useScrolled(scrollRef);

	const tableConfig = useStickyTable({
		columns: [
			{
				width: 'minmax(200px, 2fr)',
				isSticky: true,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(80px, 1fr)',
				isSticky: false,
			},
		],
	});

	const productCategories = getProductCategories(
		currentLocation?.products ?? []
	);

	const [templateState, setTemplateState] = useState<TemplateStateType | null>(
		null
	);
	const debouncedTemplate = useDebounce(templateState, 600);

	const handleUpdate =
		({ productId, weekDay }: { productId: number; weekDay: number }) =>
		(value: number) => {
			// If there is no templateState ie. internal state of template (these exist for debouncing),
			// fall back to the latest fetched value from the API
			const currentTemplateData = templateState ?? templateData;

			// If none of them are defined (shouldn't happen in real life, but can according to typing), do nothing
			if (currentTemplateData === undefined) {
				return;
			}

			// Find the relevant entry to update, if any
			const entryToUpdate = currentTemplateData.templateValues.find(
				(value) => value.productId === productId && value.weekDay === weekDay
			);

			// If there is no entry, or the entry value would be the same, skip the update
			if (entryToUpdate === undefined || entryToUpdate.value === value) {
				return;
			}

			// Set the internal template state (will be debounced and persisted once the bounce is done)
			setTemplateState({
				...currentTemplateData,
				templateValues:
					getRowsForUpdate(currentTemplateData.templateValues, productId, {
						...entryToUpdate,
						value,
					}) || [],
			});
		};

	useEffect(() => {
		// Persist template changes only after the changes have debounced
		if (debouncedTemplate !== null) {
			saveTemplate(debouncedTemplate);
		}
	}, [debouncedTemplate, saveTemplate]);

	return (
		<div
			className="table table__scrollable"
			style={{ ...tableConfig.table.style }}
			ref={scrollRef}
		>
			{isLoading && <LoadingTable />}
			{!isLoading &&
				productCategories.map(({ title, id: categoryId }) => (
					<div
						key={categoryId}
						style={{ paddingBottom: '1rem', width: 'fit-content' }}
					>
						<div
							style={{
								...tableConfig.row.sticky.style,
								borderBottom: '1px solid #E3E0D4',
								width: '100%',
							}}
						>
							{[title, ...WeekHeaders].map((header, index) => (
								<div
									style={tableConfig.cells.sticky[index].style}
									className={classNames(['table__header__cell'], {
										'table__header__cell--shadowed': isScrolled && index === 0,
									})}
									key={header}
								>
									<p
										className={classNames(['table__header__cell__data'], {
											'table__header__cell__data--title': index === 0,
										})}
									>
										{header}
									</p>
								</div>
							))}
						</div>
						{currentLocation?.products
							?.filter(
								(product) => product.productCategory[0].id === categoryId
							)
							.map(({ id: productId, title, amountPerParcel }) => {
								return (
									<div
										key={productId + title}
										style={{
											...tableConfig.row.scrolling.style,
											borderBottom: '1px solid #E3E0D4',
										}}
										className="table__row"
									>
										<div
											style={{
												...tableConfig.cells.sticky[0].style,
												borderRight: '1px solid #E3E0D4',
											}}
											className={classNames(
												['table__cell', 'table__cell--title'],
												{
													'table__cell--shadowed': isScrolled,
												}
											)}
										>
											{title}
										</div>
										{templateData &&
											getTemplateEntries(productId, templateData.templateValues)
												.filter(
													(templateProduct) =>
														Number(templateProduct.productId) ===
														Number(productId)
												)
												.map(({ value, weekDay, productId }, cellNumber) => {
													return (
														<TableCell
															key={weekDay}
															isEditable={true}
															isLocked={false}
															isTotal={false}
															value={value}
															style={
																tableConfig.cells.scrolling[cellNumber + 1]
																	.style
															}
															amountPerParcel={amountPerParcel}
															handleChange={handleUpdate({
																productId,
																weekDay,
															})}
														/>
													);
												})}
									</div>
								);
							})}
					</div>
				))}
		</div>
	);
};
