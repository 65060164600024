import { FC, Fragment, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { LocationContext } from '../Core/Contexts/LocationContext';
import { useGetUser } from '../LoginPage/UserForm/Hooks/LoginHook';

type Props = {
	children: JSX.Element;
};

export const Authenticator: FC<Props> = ({ children }) => {
	const { data: user, isLoading } = useGetUser();

	if (isLoading) {
		return <div></div>;
	}

	if (!user && !isLoading) {
		return <Navigate to="/authenticate" />;
	}

	if (!isLoading && user) {
		return <Fragment>{children}</Fragment>;
	}

	return null;
};

export const RouteSelector = () => {
	const [, setSelectedLocation] = useContext(LocationContext);
	const { data: user } = useGetUser();

	const defaultLocation = user?.relatedLocations[0];

	if (!defaultLocation) {
		return null;
	}

	// set the selected location to the users first location
	setSelectedLocation(defaultLocation.id);

	if (defaultLocation.locationType === 'sales') {
		return <Navigate to="/orders" />;
	}

	if (defaultLocation.locationType === 'production') {
		return <Navigate to="/production" />;
	}

	// keep the null render as a last resort
	return null;
};
