import { RefObject, useEffect, useState } from 'react';

export const useScrolled = (
	ref: RefObject<HTMLDivElement>,
	defaultValue = false
) => {
	const [isScrolled, setIsScrolled] = useState<boolean>(defaultValue);

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		const currentCopy = ref.current;

		const listenerFn = (event: Event) => {
			// @ts-ignore
			const scrollLeft = event.target?.scrollLeft;

			if (scrollLeft > 4 && isScrolled === false) {
				setIsScrolled(true);
			}

			if (scrollLeft === 0 && isScrolled === true) {
				setIsScrolled(false);
			}
		};

		currentCopy.addEventListener('scroll', listenerFn);

		return () => {
			currentCopy.removeEventListener('scroll', listenerFn);
		};
	}, [ref, isScrolled]);

	return [isScrolled];
};
