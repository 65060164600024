import { FC } from 'react';
import { Pages } from '../../Enums';
import { NavButton } from '../Inputs/NavButton/NavButton';
import { Options } from '../Inputs/Options/Options';
import './Footer.scss';

type Props = {
	page: Pages;
};

/**
 * A component containing the footer elements of the app
 */
export const FooterContainer: FC<Props> = ({ page }) => {
	const Actions: FC = () => {
		switch (page) {
			case Pages.PRODUCTION_PAGE:
				return (
					<NavButton
						title="Produktion"
						icon="Production"
						isActive={page === Pages.PRODUCTION_PAGE}
						path="/production"
					/>
				);
			default:
				return (
					<>
						<NavButton
							title="Bestilling"
							icon="OrderingIcon"
							isActive={page === Pages.ORDERS_PAGE}
							path="/orders"
						/>
						<NavButton
							title="Spild"
							icon="TrashIcon"
							isActive={page === Pages.WASTE_PAGE}
							path="/waste"
						/>
						<NavButton
							title="Skabelon"
							icon="TemplateIcon"
							isActive={page === Pages.TEMPLATE_PAGE}
							path="/template"
						/>
					</>
				);
		}
	};

	return (
		<div className="footer-container">
			<div className="footer-container__log-out">
				<NavButton
					isActive={true}
					title="Log ud"
					icon="Logout"
					path="/authenticate"
				/>
			</div>
			<div className="footer-container__button-group">
				<Actions />
			</div>
			<div className="footer-container__locations">
				<Options />
			</div>
		</div>
	);
};
