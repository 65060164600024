import { Product, ProductCategory } from '../SharedTypes/Models';

export const getProductCategories = (products: Product[] | undefined) => {
  if (!products) {
    return [];
  }
  return products
    .map((product) => product.productCategory[0])
    .reduce<ProductCategory[]>((acc, cateogry) => {
      if (!acc.find((productCategory) => productCategory.id === cateogry.id)) {
        acc.push(cateogry);
      }
      return acc;
    }, []);
};
