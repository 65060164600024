import { Login } from './Login/Login';
import './LoginPage.scss';

export const LoginPage = () => {
  return (
    <div className='login-page'>
      <div className='login-page__login-container'>
        <Login />
      </div>
    </div>
  );
};
