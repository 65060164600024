import { CSSProperties, forwardRef, HTMLAttributes, useMemo } from 'react';
import classNames from 'classnames';

import './Icon.scss';

import * as icons from './IconList';

interface Props extends HTMLAttributes<HTMLElement> {
  name: string;
  className?: string;
  width: number;
  style?: CSSProperties;
}

export const Icon = forwardRef<HTMLDivElement, Props>(
  ({ name, className, width, style, ...props }, ref) => {
    const Icon = useMemo(() => findIcon(name, icons), [name]);
    const styleProp = useMemo(
      () => ({ ...style, width: `${width}px` }),
      [width, style]
    );

    const myClassName = classNames('Icon', `Icon--${name}`, className);

    return (
      <div className={myClassName} style={styleProp} ref={ref} {...props}>
        <div className='Icon__element'>
          <Icon />
        </div>
      </div>
    );
  }
);

function findIcon(name: string, list: any) {
  const found = Object.keys(list).find((iconName) => iconName === name);
  if (found) {
    return list[found];
  }

  return null;
}
