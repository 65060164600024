import './Options.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon/Icon';
import classNames from 'classnames';
import { LocationContext } from '../../../Contexts/LocationContext';
import { LocationType } from '../../../SharedTypes/Models';
import { useNavigate } from 'react-router-dom';
import { locationTitle as getLocationTitle } from './utils/getLocationTitle';
import { useOnClickOutside } from 'usehooks-ts';
import { useGetMyLocations } from '../../../Hooks/LocationHooks';

export const Options = () => {
	const { data: locations } = useGetMyLocations();
	const [showOptions, setShowOptions] = useState(false);
	const [selectedLocation, setSelectedLocation] = useContext(LocationContext);

	const navigate = useNavigate();
	const ref = useRef(null);

	useEffect(() => {
		if (!selectedLocation && locations) {
			setSelectedLocation(locations[0].id);
		}
	}, [locations, selectedLocation, setSelectedLocation]);

	//!temporary Sends everyone back to orders
	const handleLocationSelected = (
		locationId: number,
		locationType: LocationType
	) => {
		if (locationType === 'production') {
			setSelectedLocation(locationId);
			navigate('/production');
		} else {
			setSelectedLocation(locationId);
			navigate('/orders');
		}
	};

	// Hide the popup when clicking outside the popup
	const handleClickOutside = () => {
		setShowOptions(false);
	};

	// Toggle the popup
	const handleOptionsClick = () => {
		setShowOptions(!showOptions);
	};

	// Setup on outside click handler
	useOnClickOutside(ref, handleClickOutside);

	if (!locations) {
		return null;
	}

	const locationTitle = getLocationTitle(locations, selectedLocation);

	return (
		<div className="options-wrapper" ref={ref}>
			{showOptions && (
				<div className="options-list">
					{locations.map(({ id, title, locationType }) => {
						return (
							<div
								key={id}
								className={classNames('options-list__item', {
									'is-active': id === selectedLocation,
								})}
								onClick={() => {
									handleLocationSelected(id, locationType);
									setShowOptions(!showOptions);
								}}
							>
								{title}
							</div>
						);
					})}
				</div>
			)}
			<div className="options" onClick={handleOptionsClick}>
				<div className="options__header">{locationTitle}</div>
				<div
					className={classNames('options__icon', {
						'options__icon--active': showOptions,
					})}
				>
					<Icon name="Carret" width={11} />
				</div>
			</div>
		</div>
	);
};
