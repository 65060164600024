import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Button } from '../../Core/Components/Inputs/Button/Button';
import { TextField } from '../../Core/Components/Inputs/Textfield/TextField';
import '../../Core/Components/Inputs/Textfield/TextField.scss';
import { LocationContext } from '../../Core/Contexts/LocationContext';
import { loginAxios, useGetUser } from './Hooks/LoginHook';
import './UserForm.scss';

const loginSchema = z.object({
	loginName: z.string().email('E-mail ugyldig'),
	password: z.string(),
});

type LoginSchema = z.infer<typeof loginSchema>;

/**
 * A component consisting of inputs used for authenticating a user
 */
export const UserForm = () => {
	const [loading, setLoading] = useState(false);
	const [authenticateError, setAuthenticateError] = useState<string | null>(
		null
	);
	const [, setSelectedLocation] = useContext(LocationContext);
	const { refetch } = useGetUser(false);
	const navigate = useNavigate();
	const { control, handleSubmit } = useForm<LoginSchema>({
		defaultValues: {
			loginName: '',
			password: '',
		},
		resolver: zodResolver(loginSchema),
		mode: 'onBlur',
		shouldFocusError: true,
	});

	const submitAction = async ({ loginName, password }: LoginSchema) => {
		setAuthenticateError(null);
		setLoading(true);
		try {
			// Send the login attempt to Craft
			await loginAxios(loginName, password);

			// If that does not error out, fetch user info
			const { data: user } = await refetch();

			const defaultLocation = user?.relatedLocations[0];

			if (!defaultLocation) {
				setLoading(false);
				return;
			}

			// set the selected location to the users first location
			setSelectedLocation(defaultLocation.id);

			setLoading(false);

			// Go to the correct path, depending on location type
			if (defaultLocation.locationType === 'sales') {
				navigate('/orders');
			} else {
				navigate('/production');
			}
		} catch (err) {
			setLoading(false);
			setAuthenticateError('Kunne ikke logge ind med de angivne oplysninger');
		}
	};

	return (
		<form onSubmit={handleSubmit(submitAction)}>
			<div className="LoginForm__text-input">
				<Controller
					name="loginName"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							name={field.name}
							type="email"
							placeholderText="E-mail"
							value={field.value}
							handleChange={field.onChange}
							theme="login"
							errorMessage={fieldState.error?.message}
							onBlur={field.onBlur}
						/>
					)}
				/>
			</div>
			<div className="LoginForm__text-input">
				<Controller
					name="password"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							name={field.name}
							type="password"
							placeholderText="Adgangskode"
							value={field.value}
							handleChange={field.onChange}
							theme="login"
							errorMessage={fieldState.error?.message}
						/>
					)}
				/>
			</div>
			{authenticateError && (
				<div
					style={{
						color: 'red',
						paddingTop: '1rem',
						fontSize: '13px',
						textAlign: 'right',
					}}
				>
					{authenticateError}
				</div>
			)}
			<div className="LoginForm__button-input">
				<Button type="submit" buttonText={loading ? 'Logger ind' : 'Login'} />
			</div>
		</form>
	);
};
