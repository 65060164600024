import { isBefore, startOfDay, startOfWeek } from 'date-fns';
import { Granularity } from '../../../../Enums';

/**
 * checks if a given date has passed to current
 * @param date the value to check against
 * @returns  true if the value is bigger than current Date/Week
 */
export const isPassed = (date: Date, granularity: Granularity) => {
	if (granularity === Granularity.WEEK) {
		return isBefore(
			startOfDay(date),
			startOfWeek(startOfDay(new Date()), { weekStartsOn: 1 })
		);
	}
	return isBefore(startOfDay(date), startOfDay(new Date()));
};
