import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { AddReduceButton } from '../AddReduceButton/AddReduceButtonComponent';
import './AmountInput.scss';

type Props = {
	remaining: number | null;
	maxValue: number;
	handleChange: (changeValue: number | null) => void;
};

export const AmountInput: FC<Props> = ({
	handleChange,
	maxValue,
	remaining,
}) => {
	const [remainingState, setRemaining] = useState<number | null>(remaining);

	const debouncedValue = useDebounce<number | null>(remainingState, 600);

	useEffect(() => {
		if (debouncedValue !== remaining && remainingState !== remaining) {
			handleChange(debouncedValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]);

	useEffect(() => {
		if (remaining !== remainingState) {
			setRemaining(remaining);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [remaining]);

	const handleIncrease = () => {
		if (remainingState !== null && remainingState < maxValue) {
			setRemaining(remainingState + 1);
		}
		if (remainingState === null) {
			setRemaining(0);
		}
	};

	const handleDecrease = () => {
		if (remainingState !== null && remainingState > 0) {
			setRemaining(remainingState - 1);
		}
		if (remaining === null) {
			setRemaining(0);
		}
	};

	function handleValue(value: string | null): void {
		if (value === '' || value === null) {
			setRemaining(null);
			return;
		}
		if (+value > maxValue) {
			setRemaining(maxValue);
		}
		if (maxValue === 0) {
			setRemaining(0);
		}
		setRemaining(+value);
	}

	const handleRemainingState = (value: string | null) => {
		if (value === '' || value === null) {
			setRemaining(null);
			return;
		}
		if (+value > maxValue) {
			setRemaining(maxValue);
			return;
		}
		if (maxValue === 0) {
			setRemaining(0);
			return;
		}
		setRemaining(+value);
	};

	return (
		<div className="amount-input">
			<AddReduceButton>
				<button
					className="add-reduce-button"
					onClick={handleDecrease}
					tabIndex={-1}
				>
					<label style={{ transform: 'translateY(-2px)' }}>-</label>
				</button>
			</AddReduceButton>
			<input
				className="amount-input__input"
				value={remainingState === null ? '' : remainingState}
				onChange={(event) => {
					const parsedValue = Number(event.target.value);
					if (!isNaN(parsedValue)) {
						handleValue(event.target.value);
						handleRemainingState(event.target.value);
					}
				}}
			></input>
			<AddReduceButton>
				<button
					className="add-reduce-button"
					onClick={handleIncrease}
					tabIndex={-1}
				>
					<label style={{ transform: 'translateY(-2px)' }}>+</label>
				</button>
			</AddReduceButton>
		</div>
	);
};
