import classNames from 'classnames';
import { FC, useCallback, useContext, useMemo, useRef } from 'react';
import { useStickyTable } from 'react-sticky-table-hook';
import { TableCell } from '../Core/Components/TableComponents/TableCell/TableCell';
import { useScrolled } from '../Core/Hooks/useScrolled';
import { ProductionData } from '../Core/SharedTypes/Models';
import { ProductionFilterContext } from '../Core/Contexts/ProductionFilterContext';

type Props = {
	productionData: ProductionData;
};

export const ProductionTableNew: FC<Props> = ({ productionData }) => {
	const [selectedLocationIds] = useContext(ProductionFilterContext);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isScrolled] = useScrolled(scrollRef);

	const filteredLocations = useMemo(() => {
		return productionData.locations.filter((location) =>
			selectedLocationIds.includes(location.locationId.toString())
		);
	}, [productionData.locations, selectedLocationIds]);

	const filteredLocationIds = filteredLocations.map((loc) => loc.locationId);

	const tableConfig = useStickyTable({
		columns: [
			{
				width: 'minmax(auto, 2fr)',
				isSticky: true,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			},
			...filteredLocations.map(() => ({
				width: 'minmax(auto, 1fr)',
				isSticky: false,
			})),
		],
	});

	const calculatedTotals = useCallback(
		(
			entries: Array<{
				entryId: string;
				entryDate: string;
				amount: number;
				isBakeoff: boolean;
				locationId: string;
			}>
		) => {
			if (!entries) {
				console.log(entries, filteredLocationIds);
				return 0;
			}

			const filtered = entries.filter((entry) =>
				filteredLocationIds.includes(entry.locationId)
			);
			const mapped = filtered.map((entry) => entry.amount);
			const reduced = mapped.reduce((acc, curr) => acc + curr, 0);

			return reduced;
		},
		[filteredLocationIds]
	);

	return (
		<div
			className="table table__scrollable"
			style={{
				...tableConfig.table.style,
			}}
			ref={scrollRef}
		>
			{productionData.categories.map(
				({ categoryName, categoryId, products }) => (
					<div
						key={categoryId}
						style={{ paddingBottom: '1rem', width: 'fit-content' }}
					>
						<div
							style={{
								...tableConfig.row.sticky.style,
								borderBottom: '1px solid #E3E0D4',
								width: '100%',
							}}
						>
							{[
								categoryName,
								'Total',
								'Afbagt',
								...(filteredLocations?.map(
									(location) => location.locationName
								) || []),
							].map((header, index) => (
								<div
									style={{
										...tableConfig.cells.sticky[index].style,
										minWidth: index === 0 ? '200px' : '120px',
									}}
									className={classNames(['table__header__cell'], {
										'table__header__cell--shadowed': isScrolled && index === 0,
									})}
									key={header}
								>
									<p
										className={classNames(['table__header__cell__data'], {
											'table__header__cell__data--title': index === 0,
										})}
									>
										{header}
									</p>
								</div>
							))}
						</div>
						{products.map(({ productName, productId, entries }) => {
							return (
								<div
									key={productId + productName}
									style={{
										...tableConfig.row.scrolling.style,
										borderBottom: '1px solid #E3E0D4',
									}}
									className="table__row"
								>
									<div
										style={{
											...tableConfig.cells.scrolling[0].style,
											borderRight: '1px solid #E3E0D4',
											minWidth: '200px',
										}}
										className={classNames(
											['table__cell', 'table__cell--title'],
											{
												'table__cell--shadowed': isScrolled,
											}
										)}
									>
										{productName}
									</div>
									<TableCell
										key={`Totals-${productId}`}
										isTotal={true}
										value={calculatedTotals(entries)}
										isLocked={false}
										isEditable={false}
										style={{
											...tableConfig.cells.scrolling[1].style,
											minWidth: '120px',
										}}
									/>
									<TableCell
										key={`Bakeoff-${productId}`}
										isTotal={true}
										value={entries
											.filter((entry) =>
												filteredLocationIds.includes(entry.locationId)
											)
											.filter((entry) => !entry.isBakeoff)
											.map((entry) => entry.amount)
											.reduce((acc, current) => acc + current, 0)}
										isLocked={false}
										isEditable={false}
										style={{ ...tableConfig.cells.scrolling[2].style }}
										isBakeoffTotal={true}
									/>
									{filteredLocations?.map((location, cellNumber) => {
										const productEntry = entries.find(
											(entry) => entry.locationId === location.locationId
										);
										return (
											<TableCell
												key={location.locationId}
												isTotal={false}
												value={productEntry?.amount ?? 0}
												isLocked={false}
												isEditable={false}
												style={{
													...tableConfig.cells.scrolling[cellNumber + 3].style,
													minWidth: '120px',
												}}
												isBakeoff={productEntry?.isBakeoff}
											/>
										);
									})}
								</div>
							);
						})}
					</div>
				)
			)}
		</div>
	);
};
