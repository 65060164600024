import classNames from 'classnames';
import { FC, useRef } from 'react';
import { useStickyTable } from 'react-sticky-table-hook';
import { LoadingTable } from '../../Core/Components/LoadingTable/LoadingTable';
import { useScrolled } from '../../Core/Hooks/useScrolled';
import { ProductEntry, Location } from '../../Core/SharedTypes/Models';
import { getProductCategories } from '../../Core/Utils/GetProductCategories';
import { AmountInput } from '../AmountInput/AmountInput';

import './WasteTable.scss';

export type Props = {
	currentLocation: Location | undefined;
	entries: ProductEntry[] | undefined;
	isLoading: boolean;
	saveEntry: (entry: ProductEntry) => void;
};

export const WasteTable: FC<Props> = ({
	currentLocation,
	entries,
	isLoading,
	saveEntry,
}) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isScrolled] = useScrolled(scrollRef);

	const tableConfig = useStickyTable({
		columns: [
			{
				width: 'minmax(auto, 100vw)',
				isSticky: true,
			},
			{
				width: 'minmax(auto, 120px)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 120px)',
				isSticky: false,
			},
			{
				width: 'minmax(auto, 120px)',
				isSticky: false,
			},
			{
				// The Calc is to signify that we should have some padding on the item in the cell
				width: 'minmax(calc(130px + 20px), calc(210px + 20px))',
				isSticky: false,
			},
		],
	});

	const productCategories = getProductCategories(
		currentLocation?.products ?? []
	);

	function updateEntry(id: number, remaining: number | null) {
		if (!entries) {
			return;
		}
		const oldEntry = entries.find((entry) => entry.id === id);
		if (!oldEntry) {
			return;
		}
		if (remaining === null) {
			saveEntry({ ...oldEntry, remaining: null, isReported: false });
			return;
		}
		if (remaining > oldEntry.ordered || remaining < 0) {
			return;
		}
		saveEntry({
			...oldEntry,
			remaining,
			isReported: true,
			sold: oldEntry.ordered - remaining,
		});
	}

	return (
		<div
			className="table table__scrollable"
			style={{ ...tableConfig.table.style }}
			ref={scrollRef}
		>
			{isLoading && <LoadingTable />}
			{!isLoading &&
				entries &&
				productCategories.map(({ title, id: categoryId }) => (
					<div
						key={categoryId}
						style={{ paddingBottom: '1rem', width: 'fit-content' }}
					>
						<div
							style={{
								...tableConfig.row.sticky.style,
								gap: '1rem',
							}}
							className="waste-table-row"
						>
							{[title, 'Spildprocent', 'Solgt', 'Bestilt', 'Rest'].map(
								(header, index) => (
									<div
										key={header}
										style={{
											...tableConfig.cells.sticky[index].style,
											minWidth: index === 0 ? '200px' : '120px',
										}}
										className={classNames(['table__header__cell'], {
											'table__header__cell--shadowed':
												isScrolled && index === 0,
										})}
									>
										<p
											className={classNames(['table__header__cell__data'], {
												'table__header__cell__data--title': index === 0,
												'waste-table-row__header-row': index !== 0,
												'waste-table-row__header-row--last': index === 4,
											})}
										>
											{header}
										</p>
									</div>
								)
							)}
						</div>
						{currentLocation?.products
							?.filter(
								(product) => product.productCategory[0].id === categoryId
							)
							.map(({ id: productId, title, productCategory }) => {
								return entries
									.filter(
										(entry) =>
											Number(productId) === Number(entry.productId) &&
											productCategory[0].id === categoryId
									)
									.map(({ waste, remaining, ordered, sold, id }) => {
										return (
											<div
												key={id}
												style={{
													...tableConfig.row.scrolling.style,
													gap: '1rem',
												}}
												className="waste-table-row"
											>
												<div
													style={{
														...tableConfig.cells.scrolling[0].style,
														minWidth: '200px',
													}}
													className={classNames(
														['table__cell', 'table__cell--title'],
														{
															'table__cell--shadowed': isScrolled,
														}
													)}
												>
													{title}
												</div>
												<div
													style={{
														...tableConfig.cells.scrolling[1].style,
														minWidth: '120px',
													}}
													className="table__cell waste-table-row__data"
												>
													{waste}%
												</div>
												<div
													style={{
														...tableConfig.cells.scrolling[2].style,
														minWidth: '120px',
													}}
													className="table__cell waste-table-row__data"
												>
													{sold}
												</div>
												<div
													style={{
														...tableConfig.cells.scrolling[3].style,
														minWidth: '120px',
													}}
													className="table__cell  waste-table-row__data"
												>
													{ordered}
												</div>
												<div
													style={{
														...tableConfig.cells.scrolling[4].style,
														marginLeft: '20px',
														minWidth: '120px',
													}}
													className="table__cell  waste-table-row__data"
												>
													<AmountInput
														remaining={remaining}
														handleChange={(newRemaining) => {
															updateEntry(id, newRemaining);
														}}
														key={productId}
														maxValue={ordered}
													/>
												</div>
											</div>
										);
									});
							})}
					</div>
				))}
		</div>
	);
};
