import { Icon } from "../../Core/Components/Icon/Icon";
import { UserForm } from "../UserForm/UserForm";

import "./Login.scss";

/**
 * A component containing all elements used for logging in
 */
export const Login = () => (
  <div className="login-container">
    <div className="login-container__content">
      <div className="login-container__content__header">
        <div className="LoginIcon">
          <Icon name="Login" width={71} />
        </div>
        <label className="login-container__content__title">Login</label>
      </div>
      <div className="login-container__content__login-form">
        <UserForm />
      </div>
      <div className="login-container__content__copyright-notice">
        Produktionsstyring · © 2022 Guldægget ApS
      </div>
    </div>
  </div>
);
