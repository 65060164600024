import { FC, ReactNode, useMemo } from 'react';
import './Header.scss';
import { useContext } from 'react';
import { ActionsContainer } from '../Actions/Actions';
import { DateContext } from '../../Contexts/DateContext';
import { Pages } from '../../Enums';
import { generateTitle } from '../../Utils/GenerateTitles';

type Props = {
	page: Pages;
	children?: ReactNode;
	button?: ReactNode;
};

/**
 * A component containing the header elements of the app
 */
export const Header: FC<Props> = ({
	page = Pages.TEMPLATE_PAGE,
	children,
	button,
}) => {
	const [selectedDate] = useContext(DateContext);
	const headerTitle = generateTitle(selectedDate[0], page);

	const showDivider = useMemo(() => children && button, [button, children]);

	return (
		<div className="header-container">
			<label className="header-container__title">{headerTitle}</label>
			{children && (
				<div className="header-container__actions">
					<ActionsContainer page={page}>{children}</ActionsContainer>
				</div>
			)}

			{showDivider && <div className="header-container__divider" />}

			{button && <div className="header-container__button">{button}</div>}
		</div>
	);
};
