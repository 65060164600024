import { FC } from 'react';
import { Icon } from '../Icon/Icon';
import './Checkbox.scss';
import classNames from 'classnames';

type Props = {
	checked: boolean;
	onClick?: () => void;
};

export const Checkbox: FC<Props> = ({ checked, onClick }) => (
	<div
		className={classNames('Checkbox', {
			'Checkbox--Checked': checked,
		})}
		onClick={onClick}
	>
		{checked ? (
			<Icon name="Check" width={11} className="Checkbox__Icon" />
		) : // <Icon name="Check" width={18} />
		null}
	</div>
);
