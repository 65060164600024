import { FC, ReactNode } from 'react';
import './AddReduceButtonComponent.scss';

type Props = {
  children: ReactNode;
};

export const AddReduceButton: FC<Props> = ({ children }) => {
  return <>{children}</>;
};
