import { FC } from 'react';
import './Button.scss';

type Props = {
	buttonText: string;
	handleClick?: () => void;
	type?: 'button' | 'submit' | 'reset' | undefined;
};
/**
 *  A simple button for clicking
 */
export const Button: FC<Props> = ({
	buttonText,
	handleClick,
	type = 'button',
}) => (
	<button
		type={type}
		className="button"
		onClick={handleClick ? handleClick : () => {}}
	>
		{buttonText}
	</button>
);
