import { format, getISOWeek } from 'date-fns';
import { da } from 'date-fns/locale';
import { Pages } from '../Enums';

export function generateTitle(currentDate: Date, page: Pages): string {
	switch (page) {
		case Pages.ORDERS_PAGE:
			return getWeekTitle(currentDate);
		case Pages.TEMPLATE_PAGE:
			return 'Skabelon';
		case Pages.PRODUCTION_PAGE:
			return 'Produktionsliste';
		default:
			return getDateTitle(currentDate);
	}
}

// A title with the week number
function getWeekTitle(date: Date) {
	const week = getISOWeek(date);

	return `Uge ${week}`;
}

// A title with the date formatted
function getDateTitle(date: Date) {
	const day = format(date, 'EEEE', { locale: da });
	const formattedDate = format(date, 'd. LLLL yyyy', { locale: da }).replace(
		/ /g,
		'\u00a0'
	);

	return `${day}, ${formattedDate}`;
}
