import { ProductEntry } from '../../../Core/SharedTypes/Models';

export const getRowsForTable = (
	productId: number,
	entries?: ProductEntry[]
) => {
	if (!entries) {
		return [];
	}

	const result = entries.filter(
		(entry) => Number(entry.productId) === Number(productId)
	);

	// Sort the result by date since we cannot guarantee the order of the entries
	return result.sort((a, b) => a.date.getTime() - b.date.getTime());
};
