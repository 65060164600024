import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Missing Root Element Panic!!!');
}

const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
