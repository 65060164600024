import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import { LoginPage } from './LoginPage/LoginPage';
import { DateContextProvider } from './Core/Contexts/DateContext';
import { LocationContextProvider } from './Core/Contexts/LocationContext';
import { OrderPage } from './OrderPage/OrderPage';
import { ProductionPage } from './ProductionPage/productionPage';
import { TemplatePage } from './TemplatePage/TemplatePage';
import { WastePage } from './WastePage/WastePage';
import { UserContextProvider } from './Core/Contexts/UserContext';
import { Authenticator, RouteSelector } from './Authenticator/Authenticator';
import { ProductionFilterContextProvider } from './Core/Contexts/ProductionFilterContext';

export const App = () => {
	const queryClient: QueryClient = new QueryClient();

	return (
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				{<ReactQueryDevtools initialIsOpen={false} />}
				<BrowserRouter>
					<DateContextProvider>
						<LocationContextProvider>
							<UserContextProvider>
								<Routes>
									<Route path="authenticate" element={<LoginPage />} />
									<Route
										index
										element={
											<Authenticator>
												<RouteSelector />
											</Authenticator>
										}
									/>
									<Route
										path="orders"
										element={
											<Authenticator>
												<OrderPage />
											</Authenticator>
										}
									/>
									<Route
										path="waste"
										element={
											<Authenticator>
												<WastePage />
											</Authenticator>
										}
									/>
									<Route
										path="template"
										element={
											<Authenticator>
												<TemplatePage />
											</Authenticator>
										}
									/>
									<Route
										path="production"
										element={
											<Authenticator>
												<ProductionFilterContextProvider>
													<ProductionPage />
												</ProductionFilterContextProvider>
											</Authenticator>
										}
									/>
								</Routes>
							</UserContextProvider>
						</LocationContextProvider>
					</DateContextProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</React.StrictMode>
	);
};
