import { Pages } from '../Core/Enums';
import { FooterContainer } from '../Core/Components/Footer/Footer';
import './TemplatePage.scss';
import { TemplateTable } from './TemplateTable';
import { Header } from '../Core/Components/Header/Header';
import { useTemplateTableData } from './templateTableDataHook';
import { LoaderButton, Props as LoaderButtonProps } from '../Core/Components/Inputs/LoaderButton/LoaderButton';
import { useCallback, useMemo } from 'react';

export const TemplatePage = () => {
	const tableData = useTemplateTableData();

	// Get the state for the refresh button
	const refreshButtonState: LoaderButtonProps['state'] = useMemo(() => {
		return tableData.isFetching || tableData.isSaving ? 'loading' : 'idle';
	}, [tableData.isFetching, tableData.isSaving]);

	// Create the handler for refreshing data
	const handleRefresh = useCallback(() => tableData.refresh(), [tableData]);

	return (
		<div className="order-page">
			<div className="order-page__header">
				<Header
					page={Pages.TEMPLATE_PAGE}
					button={
						<LoaderButton
							idleText="Opdatér"
							errorText="Fejl"
							state={refreshButtonState}
							onClick={handleRefresh}
						/>
					}
				>
				</Header>
			</div>
			<div className="order-page__content">
				<TemplateTable {...tableData} />
			</div>
			<div className="order-page__footer">
				<FooterContainer page={Pages.TEMPLATE_PAGE} />
			</div>
		</div>
	);
};
