import { Pages } from '../Core/Enums';
import { FooterContainer } from '../Core/Components/Footer/Footer';
import { WasteTable } from './WasteTable/WasteTable';

import './WastePage.scss';
import { DatePicker } from '../Core/Components/MiniDatePicker/MiniDatePicker';
import { Header } from '../Core/Components/Header/Header';
import { Printable } from '../Core/Components/Printable/Printable';
import { useWasteTableData } from './WasteTable/useWasteTableData';
import { useCallback, useMemo } from 'react';
import {
	LoaderButton,
	Props as LoaderButtonProps,
} from '../Core/Components/Inputs/LoaderButton/LoaderButton';

/**
 * Page for repporting and observing waste
 */
export const WastePage = () => {
	// Get the data for the table
	const tableData = useWasteTableData();

	// Get the state for the refresh button
	const refreshButtonState: LoaderButtonProps['state'] = useMemo(() => {
		return tableData.isFetching || tableData.isSaving ? 'loading' : 'idle';
	}, [tableData.isFetching, tableData.isSaving]);

	// Handler for the refresh button
	const handleRefresh = useCallback(() => {
		tableData.refresh();
	}, [tableData]);

	return (
		<div className="waste-page" key="Master">
			<div className="waste-page__header" key="waste-page__header">
				<Header
					page={Pages.WASTE_PAGE}
					button={
						<LoaderButton state={refreshButtonState} onClick={handleRefresh} />
					}
				>
					<Printable
						printableComponent={<WasteTable {...tableData} />}
						onBeforePrint={tableData.refresh}
						page={Pages.WASTE_PAGE}
						isLoading={tableData.isFetching}
					/>
					<DatePicker />
				</Header>
			</div>
			<div className="waste-page__content" key="waste-page__content">
				<WasteTable {...tableData} />
			</div>
			<div className="waste-page__footer" key="waste-page__footer">
				<FooterContainer page={Pages.WASTE_PAGE} />
			</div>
		</div>
	);
};
